/**
 * Created by Henry Huang on 2018/7/10.
 */
import sdMccList from '../data/mcc-list/sd.json';
import sxMccList from '../data/mcc-list/sx.json';
import mccListTypesRaw from '../data/mcc-list/types.json';
import {MCCListRaw} from "../state/swipingCards/swipingCards";

export interface MCCListType {
  type: MCCListTypeValue,
  name: string,
  rate: number[]
}

export enum MCCListTypeValue {
  SD = 'sd',
  SX = 'sx',
}

class DataService {

  public getMccListTypes(): MCCListType[] {
    return mccListTypesRaw as MCCListType[];
  }

  public getMccList(type?: MCCListTypeValue): MCCListRaw {
    let mccListRaw: MCCListRaw;
    if (type === MCCListTypeValue.SD) {
      mccListRaw = sdMccList;
    } else {
      mccListRaw = sxMccList;
    }
    return mccListRaw;
  }
}

export default DataService;

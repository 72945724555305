import {Header, Statistic} from "semantic-ui-react";
import React from "react";
import {SwipingCardsInfo} from "../state/swipingCards/swipingCards";

interface SwipingCardHeardProps {
  totalData: SwipingCardsInfo
}

const SwipingCardHeader = ({totalData}: SwipingCardHeardProps) => {
  return (
      <Header as={'h3'} textAlign={'center'}>
        <Statistic size={'small'}>
          <Statistic.Label style={{
            color: 'red'
          }}>
            手续{totalData.totalCharge === 0 ? '' : ' ≈ '}{' ' + totalData.totalCharge.toLocaleString()}
            ,
            总刷{' ' + totalData.totalAmount.toLocaleString()}
          </Statistic.Label>
          <Statistic.Value>{totalData.totalArrived === 0 ? '' : ' ≈ '}{totalData.totalArrived.toLocaleString()}{' (' + totalData.swipedCount + '/' + totalData.totalAccountCount + ')'}</Statistic.Value>
        </Statistic>
      </Header>
  )
}

export default SwipingCardHeader;

import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Slice} from "@reduxjs/toolkit/src/createSlice";
import RemoteStorage from "../../services/RemoteStorage";
import {Account} from "../../data/data";
import {AccountsState} from "./accounts";

const CM_REDUCER_NAME = "accounts";

export const loadAccountsAsync = createAsyncThunk(
    'accounts/fetchAccounts',
    async () => {
        return await new RemoteStorage().fetchAccounts();
    }
);

export const asSlice: Slice = createSlice({
    name: CM_REDUCER_NAME,
    initialState: () => {
        return {
            loading: true,
            accounts: []
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadAccountsAsync.pending, (state: AccountsState) => {
                state.loading = true;
            })
            .addCase(loadAccountsAsync.fulfilled, (state: AccountsState, action: PayloadAction<Account[]>) => {
                state.loading = false;
                state.accounts = action.payload;
            })
            .addCase(loadAccountsAsync.rejected, (state: AccountsState) => {
                state.loading = false;
            })
    },
})

export default asSlice.reducer;

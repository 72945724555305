/**
 * Created by Henry Huang on 2018/7/10.
 */
import {createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
import {
  buildInitSCState,
  doCheckAndResetMCCInfoAndTime,
  genNewAmount,
  handleSettingsChange,
  handleSwipeAllCard,
  handleSwipeCard
} from './swipingCards.utils';
import moment from "moment";
import {CardSwipingInfo, SCState} from "./swipingCards";
import {Account} from "../accounts/accounts";

const REDUCER_NAME_SC = "swipingCards";

export const scSlice: Slice = createSlice({
  name: REDUCER_NAME_SC,
  initialState: buildInitSCState([]),
  reducers: {
    setBasedAmount: (state: SCState, action: PayloadAction<number>) => {
      state.settings.basedAmount = action.payload;
      handleSettingsChange(state);
    },
    reGenBasedAmount: (state: SCState, action: PayloadAction<null>) => {
      state.settings.basedAmount = genNewAmount();
      handleSettingsChange(state);
    },
    setRate: (state: SCState, action: PayloadAction<number>) => {
      state.settings.rate = action.payload;
      handleSettingsChange(state);
    },
    doSwipeCard: (state: SCState, action: PayloadAction<CardSwipingInfo>) => {
      handleSwipeCard(state, action, true);
    },
    undoSwipeCard: (state: SCState, action: PayloadAction<CardSwipingInfo>) => {
      handleSwipeCard(state, action, false);
    },
    doSwipeAllCards: (state: SCState, action: PayloadAction<boolean>) => {
      handleSwipeAllCard(state, action.payload);
    },
    resetState: (state: SCState, action: PayloadAction<Account[]>) => {
      Object.assign(state, buildInitSCState(action.payload));
    },
    resetStateIfDateDiff: (state: SCState) => {
      if (!moment().isSame(new Date(state.bizDate), 'date')) {
        Object.assign(state, buildInitSCState(state.accountsRaw));
      }
    },
    checkAndResetMCCInfoAndTime: (state: SCState) => {
      doCheckAndResetMCCInfoAndTime(state);
    }
  }
})

export const {
  setBasedAmount,
  setRate,
  doSwipeCard,
  undoSwipeCard,
  doSwipeAllCards,
  reGenBasedAmount,
  resetState,
  checkAndResetMCCInfoAndTime,
  resetStateIfDateDiff,
} = scSlice.actions;

export default scSlice.reducer;

import React, {SyntheticEvent, useEffect, useState} from 'react'
import {Icon, Button, Statistic, Grid, Checkbox, Label, Form, Select} from 'semantic-ui-react';
import { useAppSelector, useAppDispatch } from '../../state/hooks';
import {AppDispatch} from "../../state/store";
import {
  setBasedAmount,
  setRate,
  doSwipeCard,
  undoSwipeCard,
  doSwipeAllCards,
  reGenBasedAmount,
  checkAndResetMCCInfoAndTime
} from "../../state/swipingCards/swipingCards.slice";
import moment from "moment";
import DataService, {MCCListType, MCCListTypeValue} from "../../services/DataService";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import styles from "./SwipeCardsPage.module.scss";
import {getAllPaymentMethodOptions} from "../../state/swipingCards/swipingCards.utils";
import {CardSwipingInfo, CardSwipingInfoMap, MCCAndTime, MCCListRaw, SwipeCardAccount} from "../../state/swipingCards/swipingCards";
import SwipeCardAccountView from "../../components/SwipeCardAccountView2"

const pmCheckOptions: {value: string, name: string}[] = getAllPaymentMethodOptions().map(a => ({
  value: a.value,
  name: a.name
}));
pmCheckOptions.push({
  value: 'all',
  name: '全选'
})

const SwipeCardsPage = () => {

  const dispatch: AppDispatch = useAppDispatch();

  const [time, setTime] = useState<string | undefined>(moment().format('HH:mm:ss'));
  const [pmChecked, setPmChecked] = useState<string[]>(['ap']);

  const defaultTypeValue = MCCListTypeValue.SX;
  const ds = new DataService();
  const mccListRaw: MCCListRaw = ds.getMccList(defaultTypeValue);
  const mccListOptions = ds.getMccListTypes().map((t: MCCListType) => (
      { key: t.type, value: t.type, text: t.name}
  ))

  /* eslint-disable-next-line */
  const [mccList, setMccList] = useState<MCCListRaw>(mccListRaw);
  const [mccListTypeSelected, setMccListTypeSelected] = useState<MCCListTypeValue>(defaultTypeValue);

  const onMccListTypeChange = (event: SyntheticEvent, data: DropdownProps) => {
    const type: MCCListTypeValue = data.value as MCCListTypeValue;
    setMccListTypeSelected(type);
    setMccList(ds.getMccList(type));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format('HH:mm:ss'))
      dispatch(checkAndResetMCCInfoAndTime(null));
    }, 1000);
    return () => clearInterval(interval);
  }, [dispatch]);

  const rate: number = useAppSelector(
      state => state.sc.settings.rate
  );

  const basedAmount: number = useAppSelector(
      state => state.sc.settings.basedAmount
  );

  const mccAndTime: MCCAndTime | undefined = useAppSelector(
      state => state.sc.settings.mccAndTime
  );

  const allAccounts: SwipeCardAccount[] = useAppSelector(
      state => state.sc.allAccounts
  );

  const cardSwipingInfoMap: CardSwipingInfoMap = useAppSelector(
      state => state.sc.cardSwipingInfoMap
  );

  const {totalAmount, totalArrived, totalCharge, swipedCount} = useAppSelector(
      state => state.sc.swipingInfo
  );

  const onBasedAmountChange = (e: any) => {
    const value: number = e.target.value ? e.target.value : 0;
    dispatch(setBasedAmount(Number(value)));
  }

  const onRateChange = (e: any) => {
    const value: number = e.target.value ? e.target.value : 0;
    dispatch(setRate(Number(value / 100)));
  }

  const onCardClick = (account: SwipeCardAccount) => {
    const cardSwipingInfo: CardSwipingInfo = cardSwipingInfoMap[account.name];
    if (cardSwipingInfo.swiped) {
      dispatch(undoSwipeCard(cardSwipingInfo))
    } else {
      dispatch(doSwipeCard(cardSwipingInfo))
    }
  }

  const onCheckAll = (swipeAll: boolean) => {
    dispatch(doSwipeAllCards(swipeAll));
  }

  const onRefreshBasedAmount = () => {
    dispatch(reGenBasedAmount(null));
  }

  const onPmCheck = (pm: string) => {
    if (pm === 'all') {
      if (pmChecked.indexOf(pm) > -1) {
        // uncheck all
        setPmChecked([]);
      } else {
        setPmChecked(pmCheckOptions.map(p => p.value));
      }
    }
    const index = pmChecked.indexOf(pm);
    if (index > -1) {
      pmChecked.splice(index, 1);
    } else {
      pmChecked.push(pm);
    }
    setPmChecked(pmChecked);
  }

  return (
      <Grid>
        <Grid.Row columns='equal'>
          <Grid.Column width={10}>
            <Form>
              <Form.Group>
                <Form.Input
                    label='基数金额'
                    placeholder='基数金额'
                    width={4}
                    value={basedAmount}
                    onChange={onBasedAmountChange}
                    type={'number'}
                    action
                    labelPosition={'left'}
                >
                  <input />
                  <Button onClick={onRefreshBasedAmount}>重置</Button>
                </Form.Input>
                <Form.Input
                    label='费率'
                    placeholder='费率'
                    width={4}
                    labelPosition='right'
                    defaultValue={rate * 100}
                    onChange={onRateChange}
                    type={'number'}
                    >
                  <input />
                  <Label>%</Label>
                </Form.Input>
                <Form.Field
                    control={Select}
                    label='选择类型'
                    placeholder='选择类型'
                    options={mccListOptions}
                    onChange={onMccListTypeChange}
                    value={mccListTypeSelected}
                />
                <Form.Field>
                  <Button.Group
                      style={{
                        marginLeft: 20
                      }}
                  >
                    <Button icon basic onClick={() => onCheckAll(false)}>
                      <Icon name='undo'/>
                    </Button>
                    <Button icon basic onClick={() => onCheckAll(true)}>
                      <Icon name='checkmark'/>
                    </Button>
                  </Button.Group>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={6} className={styles.totalAmount}>
            <Statistic size='mini'>
              <Statistic.Label style={{
                color: 'red'
              }}>手续{totalCharge === 0 ? '' : ' ≈ '}{' ' + totalCharge.toLocaleString()}</Statistic.Label>
              <Statistic.Label>总刷{' ' + totalAmount.toLocaleString()}</Statistic.Label>
            </Statistic>
            <Statistic size={"tiny"}>
              <Statistic.Value>{totalArrived === 0 ? '' : ' ≈ '}{totalArrived.toLocaleString()}{' (' + swipedCount + '/' + allAccounts.length + ')'}</Statistic.Value>
            </Statistic>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns='equal'>
          <Grid.Column width={16} textAlign={'center'}>
            <Statistic size='large'>
              <Statistic.Value>{ time }</Statistic.Value>
              <Statistic.Label>
                { mccAndTime?.desc }
              </Statistic.Label>
            </Statistic>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns='equal'>
          <Grid.Column width={16}>
            <div className={styles.swipeCardsContainer}>
              <div className={styles.paymentMethods}>
                {
                  pmCheckOptions.map((pmOption => (
                      <Checkbox
                          checked={pmChecked.indexOf(pmOption.value) > -1}
                          className={styles.paymentMethod}
                          label={<label>{pmOption.name}</label>}
                          onClick={() => {
                            onPmCheck(pmOption.value);
                          }}
                      />
                  )))
                }
              </div>

              <SwipeCardAccountView
                accounts={
                  allAccounts
                  .filter(account => pmChecked.indexOf(account.paymentMethod || 'card') > -1)
                }
                cardSwipingInfoMap={cardSwipingInfoMap}
                onCardClick={onCardClick}
              />

            </div>
        </Grid.Column>
      </Grid.Row>
</Grid>
)
}

export default SwipeCardsPage;

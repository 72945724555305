import React from 'react';
import {Menu} from 'semantic-ui-react';
import {Link, RouteComponentProps, withRouter} from "react-router-dom";

interface TopMenuProps {
}

function TopMenu({ location } : TopMenuProps & RouteComponentProps) {

  return (
      <Menu pointing secondary>
        <Menu.Item header>CARDS.cnhalo.com</Menu.Item>
        {/*<Menu.Item header>现在是 { moment().format('YYYY年MM月DD日') }</Menu.Item>*/}
        <Menu.Menu position='right'>
          {/*<Menu.Item*/}
          {/*    name='管理'*/}
          {/*    value='management'*/}
          {/*    active={location.pathname === '/'}*/}
          {/*    as={Link}*/}
          {/*    to={"/"}*/}
          {/*/>*/}
          {/*<Menu.Item*/}
          {/*    name='刷卡'*/}
          {/*    value='swipeCards'*/}
          {/*    active={location.pathname === '/swipeCards'}*/}
          {/*    as={Link}*/}
          {/*    to={"/swipeCards"}*/}
          {/*/>*/}
          <Menu.Item
              name='刷卡'
              value='swipeCardsExt'
              // active={location.pathname === '/swipeCardsExt'}
              active={location.pathname === '/'}
              as={Link}
              // to={"/swipeCardsExt"}
              to={"/"}
          />
          {/*<Menu.Item*/}
          {/*    name='设置'*/}
          {/*    value='settings'*/}
          {/*    active={location.pathname === '/settings'}*/}
          {/*    as={Link}*/}
          {/*    to={"/settings"}*/}
          {/*/>*/}
        </Menu.Menu>
      </Menu>
  );

}

export default withRouter(TopMenu);

import algoliaSearch from 'algoliasearch';
import config from '../config.json';
import {Account} from "../data/data";

type BizDone = {
  [name: string]: string[]
}

interface BizDoneObject {
  objectID: string,
  username: string,
  password: string,
  bizDone: BizDone
}

interface AccountsData {
  objectID: string,
  data: Account[]
}

interface APIPaths {
  accounts: string
}

interface CardsAPIConfig {
  endpoint: string,
  paths: APIPaths
}

interface AccountsResponse {
  success: boolean,
  accounts: Account[]
}

class RemoteStorage {

  async loadAccounts(): Promise<Account[]> {
    const algoliaSearchConfig = config.algoliaSearch;
    const client = algoliaSearch(algoliaSearchConfig.appId, algoliaSearchConfig.apiKey);
    const index = client.initIndex(algoliaSearchConfig.indexName);
    const object: AccountsData = await index.getObject(algoliaSearchConfig.objectIds.accounts);
    return object.data;
  }

  async fetchAccounts(): Promise<Account[]> {
    const cardsApiConfig = config.cardsApi as CardsAPIConfig;
    const response = await fetch(`${cardsApiConfig.endpoint}${cardsApiConfig.paths.accounts}`, {
      mode: 'cors'
    });
    const body = await response.json();
    return body.data;
  }

  async loadBizDone(): Promise<BizDone> {
    const algoliaSearchConfig = config.algoliaSearch;
    const client = algoliaSearch(algoliaSearchConfig.appId, algoliaSearchConfig.apiKey);
    const index = client.initIndex(algoliaSearchConfig.indexName);
    const object: BizDoneObject = await index.getObject(algoliaSearchConfig.objectIds.bizDone);
    return object.bizDone;
  }

  async saveBizDone(bizDone: BizDone) {
    const algoliaSearchConfig = config.algoliaSearch;
    const client = algoliaSearch(algoliaSearchConfig.appId, algoliaSearchConfig.apiKey);
    const index = client.initIndex(algoliaSearchConfig.indexName);
    const bizDoneObject: BizDoneObject = {
      objectID: algoliaSearchConfig.objectIds.bizDone,
      username: 'admin',
      password: 'password',
      bizDone,
    }
    return index.saveObject(bizDoneObject);
  }

}

export default RemoteStorage;

import {Button, Dropdown, Form} from "semantic-ui-react";
import {MCCListTypeValue} from "../services/DataService";
import {genNewAmount, getAllPaymentMethodOptions, mccListTypes} from "../state/swipingCards/swipingCards.utils";
import React, {useState} from "react";
import {PaymentMethod} from "../state/swipingCards/swipingCards";
import {fix2} from "../utils/mathUtils";

const options = mccListTypes.map(m => ({
    key: m.type,
    text: m.name,
    value: m.type
}))

const priorityHighOptions = [
    {
        key: true,
        text: '是',
        value: true
    },
    {
        key: false,
        text: '否',
        value: false
    }
]

const rateOptions = [
    {
        key: '0.53',
        text: '0.53%',
        value: 0.53
    },
    {
        key: '0.3',
        text: '0.3%',
        value: 0.3
    },
    {
        key: '0.38',
        text: '0.38%',
        value: 0.38
    },
]

export interface SCStarterData {
    basedAmount: number,
    rate: number,
    mccType: MCCListTypeValue,
    paymentMethods: PaymentMethod[],
    forceMachineType: boolean,
    priorityHigh: boolean,
}

export interface SCStarterProps {
    data: SCStarterData,
    onFormSubmit: (data: SCStarterData) => void
}

const SwipeCardStarter = ({data, onFormSubmit}: SCStarterProps) => {

    const [stateData, setStateData] = useState<SCStarterData>(data);
    const {basedAmount, rate, mccType, paymentMethods, priorityHigh, forceMachineType} = stateData;

    const onRefreshBasedAmount = () => {
        setStateData({
            ...stateData,
            basedAmount: genNewAmount()
        })
    }

    const onRateChange = (e: any) => {
        const value: number = e.target.value ? e.target.value : 0;
        setStateData({
            ...stateData,
            rate: Number(value / 100)
        })
    }

    const pmOptions = getAllPaymentMethodOptions().map(p => {
        return {
            key: p.value,
            value: p.value,
            text: p.name
        }
    })

    return (
        <Form>
            <Form.Group widths='equal'>
                {/*<Form.Select*/}
                {/*    fluid*/}
                {/*    width={5}*/}
                {/*    label='选择机器类型'*/}
                {/*    options={options}*/}
                {/*    placeholder='机器类型'*/}
                {/*    value={mccType}*/}
                {/*    onChange={((e, {value}) => {*/}
                {/*      setStateData({*/}
                {/*        ...stateData,*/}
                {/*        mccType: String(value) as MCCListTypeValue*/}
                {/*      })*/}
                {/*    })}*/}
                {/*/>*/}
                <Form.Input
                    width={5}
                    action
                    label='基础金额'
                    placeholder='基础金额'
                    value={basedAmount}
                    onChange={
                        ((e, {value}) => {
                            setStateData({
                                ...stateData,
                                basedAmount: Number(value)
                            })
                        })}>
                    <input/>
                    <Button onClick={onRefreshBasedAmount}>重置</Button>
                </Form.Input>
                {/*<Form.Input*/}
                {/*    width={5}*/}
                {/*    label='费率'*/}
                {/*    placeholder='费率'*/}
                {/*    type='number'*/}
                {/*    value={fix2(rate * 100)}*/}
                {/*    labelPosition={'right'}*/}
                {/*    onChange={onRateChange}>*/}
                {/*    <input/>*/}
                {/*    <Label>%</Label>*/}
                {/*</Form.Input>*/}
                <Form.Field width={4}>
                    <label>费率</label>
                    <Dropdown
                        fluid
                        selection
                        options={rateOptions}
                        placeholder='费率'
                        value={fix2(rate * 100)}
                        onChange={((e, {value}) => {
                            setStateData({
                                ...stateData,
                                rate: Number((value as number) / 100)
                            })
                        })}
                    />
                </Form.Field>

            </Form.Group>
            <Form.Group>
                {/*<Form.Field width={4}>*/}
                {/*    <label>强制机器类型</label>*/}
                {/*    <Dropdown*/}
                {/*        fluid*/}
                {/*        selection*/}
                {/*        options={priorityHighOptions}*/}
                {/*        placeholder='选择是否强制机器类型'*/}
                {/*        value={forceMachineType}*/}
                {/*        onChange={((e, {value}) => {*/}
                {/*            setStateData({*/}
                {/*                ...stateData,*/}
                {/*                forceMachineType: value as boolean*/}
                {/*            })*/}
                {/*        })}*/}
                {/*    />*/}
                {/*</Form.Field>*/}
                {/*<Form.Field width={4}>*/}
                {/*  <label>优先刷卡</label>*/}
                {/*  <Dropdown*/}
                {/*      fluid*/}
                {/*      selection*/}
                {/*      options={priorityHighOptions}*/}
                {/*      placeholder='选择是否优先刷卡'*/}
                {/*      value={priorityHigh}*/}
                {/*      onChange={((e, {value}) => {*/}
                {/*        setStateData({*/}
                {/*          ...stateData,*/}
                {/*          priorityHigh: value as boolean*/}
                {/*        })*/}
                {/*      })}*/}
                {/*  />*/}
                {/*</Form.Field>*/}
                {/*<Form.Field width={12}>*/}
                {/*  <label>支付方式</label>*/}
                {/*  <Dropdown*/}
                {/*      clearable*/}
                {/*      fluid*/}
                {/*      multiple*/}
                {/*      search*/}
                {/*      selection*/}
                {/*      options={pmOptions}*/}
                {/*      placeholder='选择支付方式'*/}
                {/*      value={paymentMethods}*/}
                {/*      onChange={((e, {value}) => {*/}
                {/*        setStateData({*/}
                {/*          ...stateData,*/}
                {/*          paymentMethods: value as PaymentMethod[]*/}
                {/*        })*/}
                {/*      })}*/}
                {/*  />*/}
                {/*</Form.Field>*/}
            </Form.Group>
            {/*<Header as={'h5'}>*/}
            {/*  {*/}
            {/*    getMCCAndTime(moment(), mccType).desc*/}
            {/*  }*/}
            {/*</Header>*/}
            <Form.Button
                primary
                onClick={() => {
                    onFormSubmit(stateData);
                }}
            >
                开始
            </Form.Button>
        </Form>
    )
}

export default SwipeCardStarter;

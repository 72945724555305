/**
 * Created by Henry Huang on 2018/7/10.
 */
import {configureStore, ThunkAction, Action, combineReducers} from '@reduxjs/toolkit';
// @ts-ignore
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import {ReduxCompatibleReducer} from "redux-actions";
import swipingCardsReducer from "./swipingCards/swipingCards.slice";
import cardsManagementReducer from "./cardsManagement/cardsManagement.slice";
import accountsReducer from "./accounts/accounts.slice";
import {SCState} from "./swipingCards/swipingCards";
import {CMState} from "./cardsManagement/cardsManagement";
import {AccountsState} from "./accounts/accounts";

const rootReducers: ReduxCompatibleReducer<any, any> = combineReducers({
  sc: swipingCardsReducer,
  // cm: cardsManagementReducer,
  as: accountsReducer
})

export interface AppState {
  sc: SCState,
  cm: CMState,
  as: AccountsState
}

const persistConfig = {
  key: 'cards-rt',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
  reducer: persistedReducer
})

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    Action<string>
    >;

export default store

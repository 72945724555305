import {SCOrderStep, SwipeCardAccount} from "../state/swipingCards/swipingCards";
import {Button, Header, Tab, TabPane} from "semantic-ui-react";
import SwipeCardAccountView from "./SwipeCardAccountView2";
import {fix2} from "../utils/mathUtils";

interface SwipeCardsStepsProps {
    scOrderSteps: SCOrderStep[],
    priorityHigh: boolean,
    onDelete: (index: number) => void,
    onSwipe: (stepIndex: number, account: SwipeCardAccount) => void,
}

const SwipeCardsSteps = (props: SwipeCardsStepsProps) => {

    let activeTabIndex: number = 0;
    const panes = props.scOrderSteps.map((sc, index) => {
        return {
            menuItem: `${sc.swipingCardsSettings.basedAmount},${fix2(sc.swipingCardsSettings.rate * 100) + '%'}`,
            render: () => (
                <TabPane attached={false} key={sc.index}>
                    <div>
                        <Header as={'h3'}>
                            <Button
                                color={'red'}
                                onClick={() => props.onDelete(sc.index)}>
                                删除
                            </Button>
                        </Header>
                        <SwipeCardAccountView
                            accounts={sc.accounts}
                            cardSwipingInfoMap={sc.cardSwipingInfoMap}
                            onCardClick={
                                (account) => {
                                    props.onSwipe(sc.index, account);
                                }
                            }
                        />
                    </div>
                </TabPane>
            )
        }
    })


    return (
        <div>
            <Tab menu={{ secondary: true, pointing: true }} panes={panes}/>
        </div>
    )
};

export default SwipeCardsSteps;

import {Card, Checkbox, Image} from "semantic-ui-react";
import styles from "./SwipeCardAccountView.module.scss";
import React from "react";
import {CardSwipingInfoMap, SwipeCardAccount} from "../state/swipingCards/swipingCards";

interface SwipeCardAccountViewProps {
  accounts: SwipeCardAccount[],
  cardSwipingInfoMap: CardSwipingInfoMap,
  onCardClick: (account: SwipeCardAccount) => void
}

const getPaymentMethodDesc = (pm?: string) => {
  if (pm === "ap") {
    return "Apple Pay";
  } else if (pm === 'ysf') {
    return "云闪付二维码";
  } else if (pm === 'lzf') {
    return "龙支付二维码";
  } else {
    return "刷卡";
  }
}

const SwipeCardAccountView = ({ accounts, cardSwipingInfoMap, onCardClick }: SwipeCardAccountViewProps) => {

  return (
      <Card.Group>
        {
          accounts
          .map(account => {
            return (
                <Card
                    key={account.name}
                    link
                    onClick={
                      () => {
                        onCardClick(account);
                      }
                    }
                >
                  <Card.Content className={
                    cardSwipingInfoMap[account.name]?.swiped ? styles.cardSwiped : ''
                  }>
                    <Image
                        size='tiny'
                        floated={"right"}
                        src={require(`../static/image/bank/${account.type}.svg`)}
                    />
                    <Card.Header>{account.title}</Card.Header>
                    <Card.Meta>{account.remark}</Card.Meta>
                    <Card.Description>
                      <Checkbox label={account.amount + ' ' + getPaymentMethodDesc(account.paymentMethod)} checked={
                        cardSwipingInfoMap[account.name] ? cardSwipingInfoMap[account.name].swiped : false
                      }/>
                    </Card.Description>
                  </Card.Content>
                </Card>
            )
          })
        }
      </Card.Group>
  )
}

export default SwipeCardAccountView;

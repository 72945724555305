import amountRange from "../../data/amountRange.json";
import types from "../../data/types.json";
// import accountsRaw from "../../data/accounts.json";
import {minZeroFix2} from "../../utils/mathUtils";
import {PayloadAction} from "@reduxjs/toolkit";
import moment, {Moment} from "moment";
import DataService, {MCCListType, MCCListTypeValue} from "../../services/DataService";
import {
  CardSwipingInfo,
  CardSwipingInfoMap,
  MCCAndTime,
  MCCInfo,
  MCCListRaw,
  PaymentMethod,
  PaymentMethodOption,
  SCState,
  SwipeCardAccount,
  SwipingCardsInfo,
  SwipingCardsSettings
} from "./swipingCards";
import {Account} from "../../data/data";
import mccListTypeRaw from "../../data/mcc-list/types.json";

export const genMCCAndTimeKey = (m: Moment): string => {
  const i = parseInt((m.minute() / 10) + "");
  return m.format("YYYY-MM-DD-HH-" + (i === 0 ? "00" : (i * 10)))
}

export const isHourRangeMatch = (now: Moment, hourRange: string): boolean => {
  const hours = hourRange.split("-");
  const hLeft = Number(hours[0].split(":")[0]);
  const hRight = Number(hours[1].split(":")[0]);
  const nowHour = now.hours();
  return (hLeft < hRight && hLeft <= nowHour && hRight >= nowHour) ||
      // over two days
      (hLeft > hRight && hLeft <= nowHour && nowHour < 24) ||
      (hLeft > hRight && 0 <= nowHour && nowHour < hRight);
}

export const isMinuteRangeMatch = (now: Moment, minuteRange: string): boolean => {
  const minutes = minuteRange.split("-");
  const nowMinute = now.minute();
  const mLeft = Number(minutes[0]);
  const mRight = Number(minutes[1]);
  return mLeft <= nowMinute && nowMinute <= mRight;
}

export const isTimeRangeMatch = (now: Moment, hourRange: string, minuteRange: string): boolean => {
  if (isHourRangeMatch(now, hourRange)) {
    return isMinuteRangeMatch(now, minuteRange);
  }
  return false;
}

export const getMCCAndTime = (now: Moment = moment(), type: MCCListTypeValue = MCCListTypeValue.SX): MCCAndTime => {
  const mccListRaw: MCCListRaw = new DataService().getMccList(type);
  const hourRanges: string[] = Object.keys(mccListRaw.timeRanges);
  let hourRangeMatched: string | null = null;
  let minuteRangeMatched: string | null = null;
  let mccInfos: MCCInfo[] | null = null;

  hourRangeLoop: for (let i = 0; i < hourRanges.length; i++) {
    const hourRange = hourRanges[i];
    const minuteRanges: string[] = Object.keys(mccListRaw.timeRanges[hourRange]);
    for (let j = 0; j < minuteRanges.length; j++) {
      const minuteRange = minuteRanges[j];
      if (isTimeRangeMatch(now, hourRange, minuteRange)) {
        hourRangeMatched = hourRange;
        minuteRangeMatched = minuteRange;
        mccInfos = mccListRaw.timeRanges[hourRange][minuteRange];
        break hourRangeLoop;
      }
    }
  }
  const key = genMCCAndTimeKey(now);
  if (mccInfos && hourRangeMatched && minuteRangeMatched) {
    return {
      key,
      hourRange: hourRangeMatched,
      minuteRange: minuteRangeMatched,
      mccInfos: mccInfos,
      desc: buildMccDescription(mccInfos)
    }
  } else {
    return {
      key,
      desc: buildMccDescription(null)
    };
  }
}

export const buildMccDescription = (mccInfos: MCCInfo[] | null) => {
  if (mccInfos) {
    return mccInfos.map(m => m.category).join(",");
  } else {
    return '';
  }
}

export const doCheckAndResetMCCInfoAndTime = (state: SCState) => {
  const m = moment();
  const newKey = genMCCAndTimeKey(m);
  // console.log(`old is ${state.settings.mccAndTime.key}, new is ${newKey}`);
  if (state.settings.mccAndTime && newKey !== state.settings.mccAndTime.key) {
    // console.log(`old mcc and time is ${JSON.stringify(state.settings.mccAndTime)}`)
    state.settings.mccAndTime = getMCCAndTime(m);
    // console.log(`new mcc and time is ${JSON.stringify(state.settings.mccAndTime)}`)
  }
}

export const randomRange = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) + min;
}

export const genNewAmount = (): number => {
  const level = randomRange(1, 4);
  let range: number[];
  if (level === 1) {
    range = amountRange.a;
  } else if (level === 2) {
    range = amountRange.b;
  } else {
    range = amountRange.c;
  }
  return range[randomRange(0, range.length)];
}

export const genAccountAmount = (basedAmount: number, half: boolean | undefined, index: number): number => {
  return (half ? basedAmount / 2 : basedAmount) + (index + 1) * 0.01;
}

export const buildAllAccounts = (basedAmount: number, accountsRaw: Account[]): SwipeCardAccount[] => {
  const accountTypes: { [key: string]: any } = types;
  const accounts: Account[] = accountsRaw as Account[];
  return accounts.filter(a => a.swipe).map((account, index) => {
    const half = account.half || false;
    return {
      name: account.name,
      title: account.title,
      type: account.type,
      remark: account.remark,
      bankName: accountTypes[account.type].fullName,
      amount: genAccountAmount(basedAmount, account.half, index),
      liability: Math.random() * 10000,
      half,
      paymentMethod: account.paymentMethod,
      defaultMachineType: account.defaultMachineType,
      priorityHigh: account.priorityHigh || false
    }
  });
}

export const buildCardSwipingInfoMap = (swipeCardAccounts: SwipeCardAccount[]): CardSwipingInfoMap => {
  const cardSwipingInfos: { [name: string]: CardSwipingInfo } = {};
  if (swipeCardAccounts) {
    swipeCardAccounts.forEach(swipeCardInfo => {
      cardSwipingInfos[swipeCardInfo.name] = {
        amount: swipeCardInfo.amount,
        accountName: swipeCardInfo.name,
        swiped: false
      }
    })
  }
  return cardSwipingInfos;
}

export const buildInitSCState = (accountsRaw: Account[]): SCState => {
  const basedAmount: number = genNewAmount();
  const allAccounts: SwipeCardAccount[] = buildAllAccounts(basedAmount, accountsRaw);
  const cardSwipingInfoMap: CardSwipingInfoMap = buildCardSwipingInfoMap(allAccounts);
  const settings: SwipingCardsSettings = {
    rate: 0.3 * 0.01,
    basedAmount,
    mccAndTime: getMCCAndTime(moment())
  };
  const swipingInfo: SwipingCardsInfo = {
    totalAmount: 0,
    totalArrived: 0,
    totalCharge: 0,
    swipedCount: 0
  };
  return {
    bizDate: Date.now(),
    allAccounts,
    cardSwipingInfoMap,
    settings,
    swipingInfo,
    accountsRaw
  }
}

export const handleSettingsChange = (state: SCState): void => {
  const {basedAmount, rate} = state.settings;
  let totalAmountNew = 0;
  let totalChargeNew = 0;
  let totalArrivedNew = 0;
  let swipedCountNew = 0;
  state.allAccounts.forEach((account, index: number) => {
    const amountNew = genAccountAmount(basedAmount, account.half, index);
    account.amount = amountNew
    const cardSwipingInfo: CardSwipingInfo = state.cardSwipingInfoMap[account.name];
    cardSwipingInfo.amount = amountNew;
    if (cardSwipingInfo.swiped) {
      totalAmountNew = totalAmountNew + account.amount;
      totalChargeNew = totalChargeNew + account.amount * rate;
      totalArrivedNew = totalAmountNew - totalChargeNew;
      swipedCountNew += 1;
    }
  })
  state.swipingInfo = {
    totalAmount: minZeroFix2(totalAmountNew),
    totalCharge: minZeroFix2(totalChargeNew),
    totalArrived: minZeroFix2(totalArrivedNew),
    swipedCount: minZeroFix2(swipedCountNew),
  }
}

export const genSwipingCardsInfo = (
    {totalAmount, totalArrived, totalCharge, swipedCount, totalAccountCount}: SwipingCardsInfo,
    {rate}: SwipingCardsSettings,
    amount: number,
    toSwiped: boolean
): SwipingCardsInfo => {
  return {
    totalAmount: minZeroFix2(toSwiped ? totalAmount + amount : totalAmount - amount),
    totalCharge: minZeroFix2(toSwiped ? (totalCharge + amount * rate) : (totalCharge - amount * rate)),
    totalArrived: minZeroFix2(toSwiped ? totalArrived + (amount - amount * rate) : (totalArrived - (amount - amount * rate))),
    swipedCount: minZeroFix2(toSwiped ? swipedCount + 1 : swipedCount - 1),
    totalAccountCount: totalAccountCount
  }
}

export const handleSwipeCard = (state: SCState, action: PayloadAction<CardSwipingInfo>, swiped: boolean): void => {
  const cardSwipingInfo = action.payload;
  state.cardSwipingInfoMap[cardSwipingInfo.accountName].swiped = swiped;
  state.swipingInfo = genSwipingCardsInfo(
      state.swipingInfo, state.settings, cardSwipingInfo.amount, swiped);
}

export const handleSwipeAllCard = (state: SCState, swiped: boolean): void => {
  let totalAmountNew = 0;
  let totalChargeNew = 0;
  let totalArrivedNew = 0;
  let swipedCountNew = 0;
  const {rate} = state.settings;
  Object.values(state.cardSwipingInfoMap).forEach(((info: CardSwipingInfo) => {
    if (swiped) {
      totalAmountNew = totalAmountNew + info.amount;
      totalChargeNew = totalChargeNew + info.amount * rate;
      totalArrivedNew = totalAmountNew - totalChargeNew;
      swipedCountNew += 1;
    }
    info.swiped = swiped;
  }))
  state.swipingInfo = {
    totalAmount: minZeroFix2(totalAmountNew),
    totalCharge: minZeroFix2(totalChargeNew),
    totalArrived: minZeroFix2(totalArrivedNew),
    swipedCount: minZeroFix2(swipedCountNew),
  }
}

const allPaymentMethodOptions = [
  {value: PaymentMethod.AP, name: 'Apple Pay'},
  {value: PaymentMethod.APW, name: 'Apple Pay (watch)'},
  {value: PaymentMethod.YSF, name: '云闪付二维码'},
  {value: PaymentMethod.LZF, name: '龙支付二维码'},
  {value: PaymentMethod.CARD, name: '刷卡'},
];

export const getAllPaymentMethodOptions = (): PaymentMethodOption[] => {
  return allPaymentMethodOptions;
}

export const getPaymentMethodOptionDesc = (values: PaymentMethod[]): string => {
  const ret = [];
  for (let option of allPaymentMethodOptions) {
    if (values.indexOf(option.value) > -1) {
      ret.push(option.name);
    }
  }
  return ret.join(',');
}

export const mccListTypes: MCCListType[] = mccListTypeRaw as MCCListType[];

export const getMccListType = (type: MCCListTypeValue): MCCListType => {
  for (let mccListType of mccListTypes) {
    if(mccListType.type === type) {
      return mccListType;
    }
  }
  return mccListTypes[0]
}


import {Container, Divider} from 'semantic-ui-react';
import SwipeCardStarter, {SCStarterData} from "../../components/SwipeCardStarter";
import React, {useState} from "react";
import {
  CardSwipingInfoMap,
  PaymentMethod,
  SCOrderStep,
  SwipeCardAccount,
  SwipingCardsInfo
} from "../../state/swipingCards/swipingCards";
import {MCCListTypeValue} from "../../services/DataService";
import SwipeCardsSteps from "../../components/SwipeCardsSteps";
import SwipingCardHeader from "../../components/SwipingCardHeader";
import {useAppSelector} from "../../state/hooks";
import {
  genAccountAmount,
  genNewAmount,
  genSwipingCardsInfo,
  getMccListType
} from "../../state/swipingCards/swipingCards.utils";
import arrayFindIndex from "array-find-index";

const getStep = (steps: SCOrderStep[], index: number): SCOrderStep | null => {
  for (let step of steps) {
    if (step.index === index) {
      return step;
    }
  }
  return null;
}

const buildNewStep = (index: number, data: SCStarterData, accounts: SwipeCardAccount[]): SCOrderStep => {
  const cardSwipingInfoMap: CardSwipingInfoMap = {};
  accounts.forEach(account => {
    cardSwipingInfoMap[account.name] = {
      accountName: account.name,
      amount: account.amount,
      swiped: false
    }
  })
  const step: SCOrderStep = {
    index: index,
    swipingCardsSettings: {
      basedAmount: data.basedAmount,
      rate: data.rate,
    },
    mccType: getMccListType(data.mccType),
    paymentMethods: data.paymentMethods,
    accounts,
    cardSwipingInfoMap,
    priorityHigh: false
  }
  return step;
}

const SwipeCardsExtPage = () => {

  const [index, setIndex] = useState<number>(14);

  const [initData] = useState<SCStarterData>({
    basedAmount: genNewAmount(),
    rate: 0.3 * 0.01,
    mccType: MCCListTypeValue.SX,
    paymentMethods: [PaymentMethod.AP, PaymentMethod.CARD, PaymentMethod.APW, PaymentMethod.LZF, PaymentMethod.YSF],
    forceMachineType: false,
    priorityHigh: false
  });

  const [scOrderSteps, setScOrderSteps] = useState<SCOrderStep[]>([]);
  const [totalData, setTotalData] = useState<SwipingCardsInfo>({
    totalAmount: 0,
    totalArrived: 0,
    totalCharge: 0,
    totalAccountCount: 0,
    swipedCount: 0
  });

  const allAccounts: SwipeCardAccount[] = useAppSelector(
      state => state.sc.allAccounts
  );

  const handleSubmit = (data: SCStarterData): void => {
    const accounts: SwipeCardAccount[] = []
    let amountIndex = 0;
    allAccounts.filter(a => {
      let ret = true;
      if (data.priorityHigh) {
        ret = ret && (a.priorityHigh || false)
      }
      if (data.forceMachineType) {
        ret = ret && (a.defaultMachineType === data.mccType)
      }
      return ret;
    }).forEach(a => {
      if (a.paymentMethod && data.paymentMethods.indexOf(a.paymentMethod) > -1) {
        accounts.push({
          ...a,
          amount: genAccountAmount(data.basedAmount, a.half, amountIndex)
        })
        amountIndex = amountIndex + 1;
      }
    })
    const step: SCOrderStep = buildNewStep(index, data, accounts);
    setScOrderSteps(
        [
            ...scOrderSteps,
          step
        ]
    );
    setIndex(index + 14);
    setTotalData({
      ...totalData,
      totalAccountCount: (totalData.totalAccountCount || 0) + accounts.length
    })
  }

  const handleDelete = (index: number): void => {
    const step = getStep(scOrderSteps, index);
    if (step) {
      setScOrderSteps(
          scOrderSteps.filter(sc => sc.index !== index)
      )
      let newTotalData = totalData;
      step.accounts.forEach((account => {
        const swipedNow = step.cardSwipingInfoMap[account.name].swiped;
        if (swipedNow) {
          // only delete swiped
          newTotalData = genSwipingCardsInfo(newTotalData, step.swipingCardsSettings, account.amount, false);
        }
      }))
      newTotalData.totalAccountCount = (totalData.totalAccountCount || 0) - step.accounts.length;
      // const swipedCount = getSwipedCount(step);
      setTotalData({
        ...newTotalData
      })
    }
  }

  const handleOnSwipe = (stepIndex: number, account: SwipeCardAccount): void => {
    const step = getStep(scOrderSteps, stepIndex);
    const arrayIndex = arrayFindIndex(scOrderSteps, sc => sc.index === index);
    if (step) {
      const swipedNow = step.cardSwipingInfoMap[account.name].swiped;
      const newTotalData = genSwipingCardsInfo(totalData, step.swipingCardsSettings, account.amount, !swipedNow);
      step.cardSwipingInfoMap[account.name].swiped = !swipedNow;
      setTotalData(newTotalData);
      scOrderSteps[arrayIndex] = step;
      setScOrderSteps(
          [
            ...scOrderSteps
          ]
      )
    }
  }

  return (
      <div style={{
        paddingBottom: 10
      }}>
        <Container>
            <SwipingCardHeader totalData={totalData}/>
            <SwipeCardStarter data={initData} onFormSubmit={handleSubmit}/>
        </Container>
        <Container>
          <SwipeCardsSteps scOrderSteps={scOrderSteps} priorityHigh={initData.priorityHigh} onDelete={handleDelete} onSwipe={handleOnSwipe}/>
        </Container>
      </div>
  )
};

export default SwipeCardsExtPage;

import React, {useEffect} from 'react';
import {Container, Loader} from 'semantic-ui-react';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
// import Management from './pages/management/ManagementPage';
import SwipeCardsPage from './pages/swipeCards/SwipeCardsPage';
import SwipeCardsExtPage from './pages/swipeCardsExt/SwipeCardsExtPage';
import TopMenu from './components/TopMenu';
import styles from './App.module.scss';
import {AppDispatch, AppState} from "./state/store";
import {useAppDispatch} from './state/hooks';
import {resetState} from "./state/swipingCards/swipingCards.slice";
import {loadAccountsAsync} from "./state/accounts/accounts.slice";
import {useSelector} from "react-redux";

function App() {
    const dispatch: AppDispatch = useAppDispatch();
    const {loading, accounts} = useSelector((state: AppState) => state.as);

    useEffect(() => {
        dispatch(loadAccountsAsync());
    }, [dispatch])

    if (!loading) {
        dispatch(resetState(accounts));
    }

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     dispatch(resetCMState(null));
    //     dispatch(resetSCState(null));
    //   }, 1000);
    //   return () => clearInterval(interval);
    // }, [dispatch]);
    //
    // const loading: boolean = useAppSelector((state: AppState) => state.cm.loading);
    //
    // const [blockScroll, allowScroll] = useScrollBlock();
    // if (loading) {
    //   blockScroll();
    //   dispatch(loadAccountsAsync());
    // } else {
    //   allowScroll();
    // }

    return (
        <Router>
            <Container fluid className={styles.cardsContainerLoading}>
                <TopMenu/>
                <Loader content='正在加载数据' active={loading}/>
                {
                    !loading && (
                        <Container fluid className={styles.cardsContainer}>
                            <Switch>
                                {/*<Route path="/" exact>*/}
                                {/*  <Management/>*/}
                                {/*</Route>*/}
                                <Route path="/swipeCards">
                                    <SwipeCardsPage/>
                                </Route>
                                {/*<Route path="/swipeCardsExt">*/}
                                {/*  <SwipeCardsExtPage/>*/}
                                {/*</Route>*/}
                                <Route path="/" exact>
                                    <SwipeCardsExtPage/>
                                </Route>
                                {/*<Route path="/settings">*/}
                                {/*    <SettingsPage/>*/}
                                {/*</Route>*/}
                            </Switch>
                        </Container>
                    )
                }
            </Container>
        </Router>
    );
}

export default App;
